import { IStoreUser } from "@/api/users";
import { useAuth } from "@/components/context/useAuth";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, Toolbar, Tooltip } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { useEffect, useMemo, useRef, useState } from "react";
import { CreateUser } from "./CreateUser";

import { getSalesRepUsers, IStore } from "@/api/store.ts";
import { DataTable, EnhancedTableToolBar } from "@/components/dashboard";
import { useFilterModel } from "@/hooks";
import { formatDate } from "@/utils";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

//import { RemindUser } from "./RemindUser";

interface IUserAccountRow {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_driver: boolean;
    is_admin: boolean;
    stores: IStore[];
    has_logged_in: boolean;
    last_active: Date | undefined;
    user: IStoreUser;
    store_terms_accepted: boolean;
    driver_terms_accepted: boolean;
    driver_coverage_expires_at: Date | undefined;
}

export const SalesStoreAccounts = () => {
    const { user } = useAuth();
    const [showCreateUser, setShowCreateUser] = useState<boolean>(false);
    const [userAccountRows, setUserAccountRows] = useState<IUserAccountRow[]>(
        []
    );
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const { filterModel, handleFilterModelChange } = useFilterModel();

    const [getStoreAccountsUsersState, getStoreAccountsUsersActions] =
        useAsync(getSalesRepUsers);

    useEffect(() => {
        if (user?.id !== undefined) {
            getStoreAccountsUsersActions.execute(
                user.id,
                paginationModel.page + 1,
                paginationModel.pageSize,
                filterModel
            );
        }
    }, [
        user?.id,
        getStoreAccountsUsersActions,
        showCreateUser,
        paginationModel.page,
        paginationModel.pageSize,
        filterModel,
    ]);

    const columns: GridColDef<IUserAccountRow>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                sortingOrder: ["desc", "asc"],
                display: "flex",
            },
            { field: "first_name", headerName: "First Name", display: "flex" },
            { field: "last_name", headerName: "Last Name", display: "flex" },
            { field: "email", headerName: "Email", display: "flex" },
            {
                field: "is_driver",
                headerName: "Driver",
                type: "boolean",
            },
            { field: "is_admin", headerName: "Admin", type: "boolean" },
            { field: "is_sales", headerName: "Sales", type: "boolean" },
            {
                field: "stores",
                headerName: "Store(s)",
                display: "flex",
                maxWidth: 200,
                renderCell({ row }) {
                    return (
                        <Box
                            display={"flex"}
                            flexWrap={"wrap"}
                            gap={1}
                            width={"100%"}
                        >
                            {row.stores.map((store) => (
                                <Tooltip title={store.name} key={store.id}>
                                    <Chip label={store.name} size="small" />
                                </Tooltip>
                            ))}
                        </Box>
                    );
                },
            },
            {
                field: "store_terms_accepted",
                headerName: "Store Terms Accepted",
                type: "boolean",
            },
            {
                field: "driver_terms_accepted",
                headerName: "Driver Terms Accepted",
                type: "boolean",
            },
            {
                field: "has_logged_in",
                headerName: "Logged In",
                type: "boolean",
            },
            {
                field: "last_active",
                headerName: "Last Active",
                valueFormatter: (value) => {
                    if (!value) return value;

                    return formatDate(value);
                },
                display: "flex",
            },
            {
                field: "driver_coverage_expires_at",
                headerName: "Coverage Expiration Date",
                valueFormatter: (value) => {
                    if (!value) return "";

                    return format(value, "MMM dd, yyyy");
                },
                display: "flex",
            },
            // {
            //     field: "actions",
            //     type: "actions",
            //     getActions: (params: GridRowParams) => [
            //         <GridActionsCellItem
            //             icon={<EditIcon />}
            //             label="Edit user"
            //             onClick={() => {
            //                 navigate(`${params.row.id}/edit`);
            //             }}
            //         />,
            //         // <RemindUser user={params.row.user} />,
            //         <DeleteUser
            //             user={params.row.user}
            //             setRefresh={setRefresh}
            //         />,
            //     ],
            // },
        ],
        []
        // [navigate]
    );

    const autoSizeOptions = {
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    };

    useEffect(() => {
        if (
            getStoreAccountsUsersState.status === "success" &&
            getStoreAccountsUsersState.result
        ) {
            const userRows = getStoreAccountsUsersState.result.items.map(
                (user) => {
                    return {
                        id: user.id,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        is_driver: user.is_driver,
                        is_admin: user.is_admin,
                        is_sales: user.is_sales,
                        stores: user.stores,
                        has_logged_in: user.has_logged_in,
                        last_active:
                            user.last_active && new Date(user.last_active),
                        user,
                        store_terms_accepted: user.store_terms_accepted,
                        driver_terms_accepted: user.driver_terms_accepted,
                        driver_coverage_expires_at:
                            user.driver_coverage_expires_at &&
                            new Date(
                                `${user.driver_coverage_expires_at}T00:00:00`
                            ),
                    } as IUserAccountRow;
                }
            );

            setUserAccountRows(userRows);
        }
    }, [getStoreAccountsUsersActions, getStoreAccountsUsersState]);

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(getStoreAccountsUsersState.result?.total || 0);

    const rowCount = useMemo(() => {
        if (getStoreAccountsUsersState.result?.total !== undefined) {
            rowCountRef.current = getStoreAccountsUsersState.result?.total;
        }
        return rowCountRef.current;
    }, [getStoreAccountsUsersState.result?.total]);

    return (
        <Box
            sx={{
                backgroundColor: "#F4F7FE",
                height: "100vh",
                width: "100%",
                overflowX: "auto",
            }}
        >
            {/* Spacing for mobile app bar */}
            <Toolbar sx={{ display: { sm: "none" } }} />
            {!showCreateUser && (
                <>
                    <Box p={"1em"} mb={8}>
                        <EnhancedTableToolBar
                            title={"User Accounts"}
                            action={
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#40ABBA",
                                        "&:hover": {
                                            backgroundColor: "#309BA0",
                                        },
                                    }}
                                    startIcon={<AddIcon />}
                                    size="small"
                                    onClick={() =>
                                        setShowCreateUser(!showCreateUser)
                                    }
                                >
                                    Add User
                                </Button>
                            }
                        />
                        <DataTable
                            loading={
                                getStoreAccountsUsersState.status === "loading"
                            }
                            rows={userAccountRows}
                            columns={columns}
                            autoSize
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "id", sort: "desc" }],
                                },
                            }}
                            rowCount={rowCount}
                            paginationMode={"server"}
                            autosizeOptions={autoSizeOptions}
                            pageSizeOptions={[10, 25, 50]}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            onFilterModelChange={handleFilterModelChange}
                            onSortModelChange={handleFilterModelChange}
                            filterMode={"server"}
                            sortingMode={"server"}
                        />
                    </Box>
                </>
            )}
            {showCreateUser && (
                <CreateUser setShowCreateUser={setShowCreateUser} />
            )}
        </Box>
    );
};
