import { DBStoreOrder } from "@/api/store";
import { getOrderStatus } from "@/utils";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OrderStatusChip } from "../status/OrderStatusChip";
import { EditOrderMenu } from "./EditOrderMenu";

interface IOrderDetailsToolbarProps {
    order: DBStoreOrder;
    admin?: boolean;
}

export const OrderDetailsToolbar = ({
    order,
    admin,
}: IOrderDetailsToolbarProps) => {
    const navigate = useNavigate();

    return (
        <Toolbar disableGutters>
            <IconButton onClick={() => navigate(-1)}>
                <ArrowBackRoundedIcon />
            </IconButton>
            <Typography ml={1} mr={2} fontWeight={600} variant={"h5"}>
                #{order.id}
            </Typography>
            {order.payment_intent_paid && (
                <>
                    <Stack direction={"row"} gap={0.5}>
                        {admin && (
                            <OrderStatusChip
                                orderStatus={
                                    order.paid_driver ? "paid" : "unpaid"
                                }
                                order_id={order.id}
                            />
                        )}
                        <OrderStatusChip
                            orderStatus={getOrderStatus(order)}
                            order_id={order.id}
                        />
                    </Stack>
                    <Box flexGrow={1} />
                    <EditOrderMenu />
                </>
            )}
        </Toolbar>
    );
};
