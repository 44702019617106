import { FormAutoCompleteElement } from "@rhf-kit/mui";
import { useEffect } from "react";
import React from "react";
import { IStoreUser, getAllUsers } from "@/api/users";
import { useAsync } from "@react-hookz/web";
import { UserAutoCompleteOption } from "./UserAutoCompleteOption";
import { useAuth } from "@/components/context/useAuth";
import { useFormContext } from "react-hook-form";
import { IStore } from "@/api/store";

const UserAutoComplete = ({ store }: { store: IStore | undefined }) => {
    const { user } = useAuth();
    const [users, setUsers] = React.useState<IStoreUser[]>([]);
    const [getUsersState, getUsersActions] = useAsync(getAllUsers);
    const { setValue } = useFormContext();

    React.useEffect(() => {
        if (user?.is_admin) {
            getUsersActions.execute();
        } else {
            if (store) {
                if (store.salesperson) {
                    setUsers([store.salesperson]);
                    setValue("salesRep", store.salesperson.id);
                } else {
                    setValue("salesRep", undefined);
                }
            } else {
                if (user?.is_sales) {
                    setUsers([user as IStoreUser]);
                    setValue("salesRep", user.id);
                }
            }
        }
    }, [getUsersActions, user?.is_admin, user?.id, user, setValue, store]);

    useEffect(() => {
        if (getUsersState.status === "success" && getUsersState.result) {
            setUsers(getUsersState.result.items);
        }
    }, [getUsersState.status, getUsersState.result]);

    React.useEffect(() => {
        if (getUsersState.status === "success" && getUsersState.result) {
            setUsers(getUsersState.result.items);
        }
    }, [getUsersState.status, getUsersState.result]);

    return (
        <FormAutoCompleteElement
            name={"salesRep"}
            label={"Sales Representative"}
            fullWidth
            matchId
            disabled={!user?.is_admin}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
                (option as IStoreUser).first_name +
                " " +
                (option as IStoreUser).last_name
            }
            noOptionsText={"No users"}
            autoComplete
            autoHighlight
            includeInputInList
            options={users}
            textFieldProps={{ margin: "normal" }}
            loading={getUsersState.status === "loading"}
            renderOption={(props, option) => (
                <UserAutoCompleteOption
                    key={option.id}
                    option={option}
                    props={props}
                />
            )}
        />
    );
};

export default UserAutoComplete;
