import { createHighValue } from "@/api/settings";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { FormButton, FormContainer, FormTextFieldElement } from "@rhf-kit/mui";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    mutateHighValues: () => void;
}

interface FormData {
    minimumValue: number;
    upcharge: number;
}

export const CreateHighValueModal = ({
    open,
    setOpen,
    mutateHighValues,
}: Props) => {
    const createHighValueCharge = useMutation({
        mutationFn: createHighValue,
        onSuccess: () => {
            mutateHighValues();
            enqueueSnackbar(`High Value upcharge created successfully`, {
                variant: "success",
            });
            setOpen(false);
        },
        onError: (error: AxiosError<{ detail: string }>) => {
            if (
                error.response &&
                error.response.data &&
                error.response.data.detail
            ) {
                enqueueSnackbar(error.response.data.detail, {
                    variant: "error",
                });
            }
        },
    });

    const handleSubmit = (data: FormData) => {
        createHighValueCharge.mutate({
            minimum_value: data.minimumValue * 100,
            upcharge: data.upcharge * 100,
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>
                <Toolbar variant="dense">
                    <Typography variant="h6" fontWeight={600}>
                        Create a new high value upcharge
                    </Typography>
                    <Box flexGrow={1} />
                    <IconButton edge="end" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <DialogContent>
                <FormContainer
                    defaultValues={{ minimumValue: 0, upcharge: 0 }}
                    onSuccess={handleSubmit}
                >
                    <Stack direction={"column"} spacing={2} my={2}>
                        <FormTextFieldElement
                            id="minimumValue"
                            name="minimumValue"
                            label="Minimum Value"
                            size="small"
                            required
                            rules={{
                                min: {
                                    value: 1,
                                    message:
                                        "Minimum Value must be at least $0.01",
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        variant="standard"
                                    >
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormTextFieldElement
                            id="upcharge"
                            name="upcharge"
                            label="Upcharge"
                            type="number"
                            rules={{
                                min: {
                                    value: 1,
                                    message: "Upcharge must be at least $0.01",
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        variant="standard"
                                    >
                                        $
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                            required
                        />
                    </Stack>
                    <FormButton variant="contained" color="primary">
                        Create High Value Upcharge
                    </FormButton>
                </FormContainer>
            </DialogContent>
        </Dialog>
    );
};
