import { useAuth } from "@/components/context/useAuth";
import { SalesStoreAccounts } from "./components/Accounts";

export const SalesUsersDashboard = () => {
    const { user } = useAuth();

    if (!user?.is_admin && !user?.is_sales) {
        return <div>Unauthorized</div>;
    }

    // Need to create a custom component for this
    return <SalesStoreAccounts />;
};

export default SalesUsersDashboard;
