import { createPaymentIntent, updatePaymentIntent } from "@/api/orders";
import { IPricing } from "@/api/settings";
import {
    DBStoreOrder,
    IStore,
    IStoreOrder,
    IUpdateOrder,
    createOrder,
    updateStoreOrder,
} from "@/api/store";
import { OrderFormElements } from "@/components/dashboard/orders/process/OrderFormElements.tsx";
import { getInitialFormData } from "@/utils";
import { FormContainer } from "@rhf-kit/mui";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export interface OrderFormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zipCode: string;
    itemSize: string;
    deliveryWindow: string;
    itemFloor: number;
    requiresAssembly: boolean;
    weightLimit: boolean;
    needsWrapped: boolean;
    elevatorAvailable: boolean;
    itemDescription: string;
    estimatedValue: string;
    itemQuantity: number;
    orderLocation?: string;
    dropoffInstructions: string;
    referral?: string;
}

interface IOrderFormProps {
    order?: DBStoreOrder;
    store?: IStore;
    pricing?: IPricing;
}

export const OrderForm = ({ order, store, pricing }: IOrderFormProps) => {
    const navigate = useNavigate();

    const initialValues: OrderFormData = getInitialFormData(order, store);

    const {
        mutate: updateOrderMutate,
        isPending: updateOrderPending,
        error: updateOrderError,
    } = useMutation({
        mutationFn: ({
            store_id,
            order_id,
            order,
        }: {
            store_id: number;
            order_id: number;
            order: IUpdateOrder;
        }) => updateStoreOrder(store_id, order_id, order),
        onError: () =>
            enqueueSnackbar("Failed to update order", {
                variant: "error",
            }),
        onSuccess: (_, { order_id }) => {
            enqueueSnackbar("Order updated successfully", {
                variant: "success",
            });

            if (pricing) {
                updatePaymentIntentMutate(order_id);
            } else {
                navigate(-1);
            }
        },
    });

    const {
        mutate: createStoreOrder,
        isPending: createStoreOrderPending,
        error: createOrderError,
    } = useMutation({
        mutationFn: ({
            store_id,
            order,
        }: {
            store_id: number;
            order: IStoreOrder;
        }) => createOrder(store_id, order),
        onError: (error) => {
            const axiosError = error as AxiosError<{
                detail: string;
            }>;

            if (axiosError.response) {
                enqueueSnackbar(axiosError.response.data.detail, {
                    variant: "error",
                });
                return;
            }

            enqueueSnackbar("Failed to create order", {
                variant: "error",
            });
        },
        onSuccess: (order: IStoreOrder) => {
            if (order.id) paymentIntentMutate(order.id);
        },
    });

    const {
        mutate: paymentIntentMutate,
        isPending: paymentIntentPending,
        error: paymentIntentError,
    } = useMutation({
        mutationFn: async (order_id: number) => {
            const paymentIntent = await createPaymentIntent(order_id);
            return paymentIntent.id;
        },
        onError: (error) => {
            const axiosError = error as AxiosError<{
                detail: string;
            }>;

            if (axiosError.response) {
                enqueueSnackbar(axiosError.response.data.detail, {
                    variant: "error",
                });
                return;
            }

            enqueueSnackbar("Failed to create payment intent", {
                variant: "error",
            });
        },
        onSuccess: (_, order_id) => {
            navigate(`${order_id}/checkout`);
        },
    });

    const {
        mutate: updatePaymentIntentMutate,
        isPending: updatePaymentIntentMutatePending,
        error: updatePaymentIntentMutatePendingError,
    } = useMutation({
        mutationFn: (order_id: number) => updatePaymentIntent(order_id),
        onError: (error) => {
            const axiosError = error as AxiosError<{
                detail: string;
            }>;

            if (axiosError.response) {
                enqueueSnackbar(axiosError.response.data.detail, {
                    variant: "error",
                });
                return;
            }

            enqueueSnackbar("Failed to update payment intent", {
                variant: "error",
            });
        },
        onSuccess: (_, order_id: number) => {
            navigate(`${order_id}/checkout`);
        },
    });

    // form submit handler - update order or create payment intent
    const onSubmit = (data: OrderFormData) => {
        // if order, update order
        if (order) {
            const updateOrderData: IUpdateOrder = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone_number: data.phoneNumber,
                delivery_address: {
                    address1: data.address1,
                    address2: data.streetAddress2,
                    city: data.city,
                    state: data.state,
                    zip_code: data.zipCode,
                },
                item_size: data.itemSize,
                item_quantity: data.itemQuantity,
                delivery_window: data.deliveryWindow,
                floor_level: data.itemFloor,
                assembly_required: data.requiresAssembly,
                max_weight: data.weightLimit,
                needs_wrapped: data.needsWrapped,
                elevator_available: data.elevatorAvailable,
                item_description: data.itemDescription,
                estimated_value: data.estimatedValue,
                dropoff_instructions: data.dropoffInstructions,
            };

            updateOrderMutate({
                store_id: order.store_id,
                order_id: order.id,
                order: updateOrderData,
            });
        }

        // if no order, create payment intent
        if (store && !order) {
            const orderData: IStoreOrder = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone_number: data.phoneNumber,
                order_location: data.orderLocation,
                delivery_address: {
                    address1: data.address1,
                    address2: data.streetAddress2,
                    city: data.city,
                    state: data.state,
                    zip_code: data.zipCode,
                },
                item_size: data.itemSize,
                item_quantity: data.itemQuantity,
                delivery_window: data.deliveryWindow,
                floor_level: data.itemFloor,
                assembly_required: data.requiresAssembly,
                max_weight: data.weightLimit,
                needs_wrapped: data.needsWrapped,
                elevator_available: data.elevatorAvailable,
                item_description: data.itemDescription,
                estimated_value: data.estimatedValue,
                store_id: store.id,
                dropoff_instructions: data.dropoffInstructions,
            };

            createStoreOrder({ store_id: store.id, order: orderData });
        }
    };

    return (
        <FormContainer defaultValues={initialValues} onSuccess={onSubmit}>
            <OrderFormElements
                error={
                    createOrderError ||
                    updateOrderError ||
                    updatePaymentIntentMutatePendingError ||
                    paymentIntentError
                }
                loading={
                    createStoreOrderPending ||
                    paymentIntentPending ||
                    updateOrderPending ||
                    updatePaymentIntentMutatePending
                }
                order={order}
                pricing={pricing}
            />
        </FormContainer>
    );
};
