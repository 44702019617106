import { Box, Paper, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { AxiosError } from "axios";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    ICreateStore,
    IStore,
    IUpdateStore,
    createStore,
    updateStore,
} from "@/api/store.ts";
import { FormContainer } from "@rhf-kit/mui";
import { StoreFormElements } from "@/components/dashboard/stores/StoreFormElements.tsx";
import { IStoreUser } from "@/api/users";

interface IStoreFormProps {
    store?: IStore;
}

interface StoreFormData {
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    salesRep: IStoreUser | undefined;
}

export const StoreForm = ({ store }: IStoreFormProps) => {
    const [updateStoreState, updateStoreActions] = useAsync(updateStore);
    const [createStoreState, createStoreActions] = useAsync(createStore);
    const navigate = useNavigate();

    // populate initial data from store
    const initialFormData: StoreFormData = {
        name: store ? store.name : "",
        address1: store ? store.store_address.address1 : "",
        address2: store ? store.store_address.address2 : "",
        city: store ? store.store_address.city : "",
        state: store ? store.store_address.state : "",
        zipCode: store ? store.store_address.zip_code : "",
        salesRep: store ? store.salesperson : undefined,
    };

    const onSubmit = (data: StoreFormData) => {
        if (store) {
            // check if data is the same as initial data
            if (_.isEqual(data, initialFormData)) return;

            const updateStoreData: IUpdateStore = {
                name: data.name,
                store_address: {
                    address1: data.address1,
                    address2: data.address2,
                    city: data.city,
                    state: data.state,
                    zip_code: data.zipCode,
                },
                salesperson: data.salesRep,
            };

            updateStoreActions.execute(store.id, updateStoreData);
        } else {
            const updateStoreData: ICreateStore = {
                name: data.name,
                store_address: {
                    address1: data.address1,
                    address2: data.address2,
                    city: data.city,
                    state: data.state,
                    zip_code: data.zipCode,
                },
                salesperson: data?.salesRep,
            };

            createStoreActions.execute(updateStoreData);
        }
    };

    useEffect(() => {
        if (updateStoreState.status === "success") {
            enqueueSnackbar("Store updated successfully!", {
                variant: "success",
            });

            // reload page
            navigate(0);
        }
        if (updateStoreState.status === "error") {
            const error = updateStoreState.error as AxiosError<{
                detail: string;
            }>;
            enqueueSnackbar(
                error.response && error.response.data
                    ? `${error.response.data.detail}`
                    : "Failed to update store.",
                {
                    variant: "error",
                }
            );
        }
    }, [navigate, updateStoreState]);

    useEffect(() => {
        if (createStoreState.status === "success") {
            enqueueSnackbar("Store created successfully!", {
                variant: "success",
            });

            // reload page
            navigate(0);
        }
    }, [navigate, createStoreState]);

    return (
        <Paper
            sx={{
                padding: "1em",
                borderRadius: "10px",
            }}
        >
            <Typography
                textAlign="center"
                fontSize={24}
                fontWeight="900"
                gutterBottom
            >
                {store ? "Store Information" : "Create Store"}
            </Typography>

            <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"1em"}
                marginBottom={"1em"}
            >
                <FormContainer
                    defaultValues={initialFormData}
                    onSuccess={onSubmit}
                >
                    <StoreFormElements
                        state={createStoreState && updateStoreState}
                        store={store}
                    />
                </FormContainer>
            </Box>
        </Paper>
    );
};
