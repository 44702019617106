import { useAuth } from "@/components/context/useAuth";
import { AdminDraftOrders } from "./components/DraftOrders";

const AdminDraftOrdersDashboard = () => {
    const { user } = useAuth();

    if (!user?.is_admin) {
        return <div>Unauthorized</div>;
    }

    return <AdminDraftOrders />;
};

export default AdminDraftOrdersDashboard;
