import { DBStoreOrder } from "@/api/store";
import { Box, Toolbar } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";

import { getDraftOrders, IDBCustomOrder } from "@/api/orders.ts";
import { DataTable } from "@/components/dashboard";
import { EnhancedTableToolBar } from "@/components/dashboard/EnhancedTableToolBar";
import { useFilterModel } from "@/hooks";
import { formatDate } from "@/utils";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useAsync } from "@react-hookz/web";
import { useNavigate } from "react-router-dom";

interface IAdminOrderRow {
    id: number;
    store?: string;
    created_at: Date;
    customer: string;
    item_description: string;
    order: DBStoreOrder | IDBCustomOrder;
}

export const AdminDraftOrders = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState<IAdminOrderRow[]>([]);
    const [draftOrdersState, draftOrdersActions] = useAsync(getDraftOrders);

    const { filterModel, handleFilterModelChange } = useFilterModel();

    // set default pagination model
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const autoSizeOptions = {
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    };

    const handleRowClick = (params: GridRowParams) => {
        navigate(`${params.row.id}`);
    };

    useEffect(() => {
        draftOrdersActions.execute(
            paginationModel.page + 1,
            paginationModel.pageSize,
            filterModel
        );
    }, [
        filterModel,
        paginationModel.page,
        paginationModel.pageSize,
        draftOrdersActions,
    ]);

    const isStoreOrder = (
        order: DBStoreOrder | IDBCustomOrder
    ): order is DBStoreOrder => {
        return "store" in order;
    };

    useEffect(() => {
        if (draftOrdersState.status === "success" && draftOrdersState.result) {
            const orderRows = draftOrdersState.result.items.map((order) => {
                return {
                    id: order.id,
                    store: isStoreOrder(order) ? order.store?.name : "N/A",
                    created_at: new Date(order.created_at),
                    customer: `${order.first_name} ${order.last_name}`,
                    item_description: order.item_description,
                    order: order,
                } as IAdminOrderRow;
            });

            setOrders(orderRows);
        }
    }, [draftOrdersState]);

    const columns: GridColDef<IAdminOrderRow>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "Order",
                sortingOrder: ["desc", "asc"],
                valueFormatter: (value) => {
                    if (!value) return value;

                    return `#${value}`;
                },
                display: "flex",
            },
            {
                field: "store",
                headerName: "Store",
                display: "flex",
            },
            {
                field: "created_at",
                headerName: "Date",
                display: "flex",
                valueFormatter: (value) => {
                    if (!value) return value;

                    return formatDate(value);
                },
            },
            { field: "customer", headerName: "Customer", display: "flex" },
            {
                field: "item_description",
                headerName: "Item Description",
                display: "flex",
            },
        ],
        []
    );

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(draftOrdersState.result?.total || 0);

    const rowCount = useMemo(() => {
        if (draftOrdersState.result?.total !== undefined) {
            rowCountRef.current = draftOrdersState.result?.total;
        }
        return rowCountRef.current;
    }, [draftOrdersState.result?.total]);

    return (
        <Box
            sx={{
                backgroundColor: "#F4F7FE",
                minHeight: "100vh",
                overflowX: "auto",
                width: "100%",
            }}
        >
            {/* Spacing for mobile app bar */}
            <Toolbar sx={{ display: { sm: "none" } }} />

            <Box sx={{ padding: "1em" }}>
                <EnhancedTableToolBar title={"Admin Draft Orders"} />
                <DataTable
                    loading={draftOrdersState.status === "loading"}
                    rows={orders}
                    sx={{
                        ".MuiDataGrid-cell:focus": {
                            outline: "none",
                        },
                        "& .MuiDataGrid-row:hover": {
                            cursor: "pointer",
                        },
                    }}
                    columns={columns}
                    onRowClick={handleRowClick}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "id", sort: "desc" }],
                        },
                    }}
                    autoSize
                    autosizeOptions={autoSizeOptions}
                    paginationMode={"server"}
                    rowCount={rowCount}
                    pageSizeOptions={[10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    filterMode={"server"}
                    sortingMode={"server"}
                    onFilterModelChange={handleFilterModelChange}
                    onSortModelChange={handleFilterModelChange}
                />
            </Box>
        </Box>
    );
};
