import { deleteHighValue, IHighValueUpcharge } from "@/api/settings";
import { Delete } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

interface Props {
    highValue: IHighValueUpcharge;
}
export const HighValueDeleteIcon = ({ highValue }: Props) => {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    const deleteMutation = useMutation({
        mutationFn: deleteHighValue,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["admin-high-value-upcharges"],
            });
        },
    });

    const handleDelete = () => {
        deleteMutation.mutate(highValue.id);
        setOpen(false);
    };

    return (
        <>
            <IconButton
                color="error"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <Delete />
            </IconButton>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle id="alert-dialog-title">
                    Delete High Value Upcharge?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this upcharge?
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button color="error" onClick={handleDelete} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
