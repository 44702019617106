import { Box, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ICreateUser, createUser } from "@/api/auth.ts";
import { IStore, addStoreAccount, removeStoreAccount } from "@/api/store.ts";
import { IStoreUser, IUpdateUser, updateUser } from "@/api/users.ts";
import {
    FormCheckboxElement,
    FormContainer,
    FormEmailElement,
    FormTextFieldElement,
} from "@rhf-kit/mui";
import { StoreAutoComplete } from "@/components/dashboard/userAccounts/StoreAutoComplete.tsx";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import { DriverCoverageDateSelector } from "./DriverCoverageDateSelector";
import { format } from "date-fns";

export interface IUserFormData {
    firstName: string;
    lastName: string;
    email: string;
    stores: IStore[];
    admin: boolean;
    driver: boolean;
    sales: boolean;
    driver_coverage_expires_at: Date | undefined;
}

interface IUserFormProps {
    user?: IStoreUser;
}

export const UserForm = ({ user }: IUserFormProps) => {
    const navigate = useNavigate();
    const isAdminSuperUser = user?.is_superuser;

    const [createUserState, createUserActions] = useAsync(createUser);
    const [updateUserState, updateUserActions] = useAsync(updateUser);
    const [addStoreAccountState, addStoreAccountActions] =
        useAsync(addStoreAccount);
    const [removeStoreAccountState, removeStoreAccountResult] =
        useAsync(removeStoreAccount);

    const initialFormData: IUserFormData = {
        firstName: user?.first_name || "",
        lastName: user?.last_name || "",
        email: user?.email || "",
        stores: user?.stores || [],
        admin: user?.is_admin || false,
        driver: user?.is_driver || false,
        sales: user?.is_sales || false,
        driver_coverage_expires_at: user?.driver_coverage_expires_at
            ? new Date(`${user?.driver_coverage_expires_at}T00:00:00`)
            : undefined,
    };

    // adds user to stores if they are selected
    const addUserToStores = async (stores: IStore[], userId: number) => {
        stores.forEach((store) => {
            addStoreAccountActions.execute(store.id, userId).catch(() => {
                enqueueSnackbar(
                    `There was an error adding the user to the store: ${store.name}`,
                    { variant: "error" }
                );
            });
        });
    };

    // removes user from stores if they are unselected
    const removeUserFromStores = async (stores: IStore[], userId: number) => {
        stores.forEach((store) => {
            removeStoreAccountResult.execute(store.id, userId).catch(() => {
                enqueueSnackbar(
                    `There was an error removing the user from the store: ${store.name}`,
                    { variant: "error" }
                );
            });
        });
    };

    // handles the form submission
    const onSubmit = (data: IUserFormData) => {
        if (user) {
            // get stores that are in the form but not in the user
            const storesToAdd = data.stores.filter(
                (dataStore) =>
                    !user.stores.some(
                        (userStore) => userStore.id === dataStore.id
                    )
            );

            // get stores that are in the user but not in the form
            const storesToRemove = user.stores.filter(
                (userStore) =>
                    !data.stores.some(
                        (dataStore) => dataStore.id === userStore.id
                    )
            );

            // add user to stores that are in the form but not in the user
            if (storesToAdd.length > 0) {
                addUserToStores(storesToAdd, user.id);
            }

            // remove user from stores that are in the user but not in the form
            if (storesToRemove.length > 0) {
                removeUserFromStores(storesToRemove, user.id);
            }

            const userData: IUpdateUser = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                is_admin: data.admin,
                is_driver: data.driver,
                is_sales: data.sales,
                driver_coverage_expires_at:
                    data.driver_coverage_expires_at &&
                    format(data.driver_coverage_expires_at, "yyyy-MM-dd"),
            };

            // update user info
            updateUserActions.execute(user.id, userData);
        }

        if (!user) {
            const userData: ICreateUser = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                is_admin: data.admin,
                is_driver: data.driver,
                is_sales: data.sales,
                stores: data.stores.map((it) => it.id) || [],
                driver_coverage_expires_at:
                    data.driver_coverage_expires_at &&
                    format(data.driver_coverage_expires_at, "yyyy-MM-dd"),
            };

            createUserActions
                .execute(userData)
                .then(() => {
                    enqueueSnackbar("User created successfully.", {
                        variant: "success",
                    });

                    navigate(0);
                })
                .catch(() => {
                    enqueueSnackbar("There was an error creating the user.", {
                        variant: "error",
                    });
                });
        }
    };

    useEffect(() => {
        if (updateUserState.status === "success") {
            enqueueSnackbar("User info edited successfully.", {
                variant: "success",
            });

            // navigate back to the user list
            navigate(-1);
        }

        if (updateUserState.status === "error") {
            enqueueSnackbar("There was an error editing the user info.", {
                variant: "error",
            });
        }
    }, [updateUserState.status, updateUserActions, navigate]);

    return (
        <FormContainer defaultValues={initialFormData} onSuccess={onSubmit}>
            <Box padding={2} display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} gap={2}>
                    {/* First Name Field */}
                    <Box flex={1}>
                        <FormTextFieldElement
                            name={"firstName"}
                            required
                            label={"First Name"}
                            size={"small"}
                            disabled={isAdminSuperUser}
                            margin={"normal"}
                            fullWidth
                        />
                    </Box>

                    {/* Last Name Field */}
                    <Box flex={1}>
                        <FormTextFieldElement
                            name={"lastName"}
                            required
                            label={"Last Name"}
                            size={"small"}
                            disabled={isAdminSuperUser}
                            margin={"normal"}
                            fullWidth
                        />
                    </Box>
                </Box>

                {/* Email Field */}
                <FormEmailElement
                    name={"email"}
                    required
                    label={"Email Address"}
                    size={"small"}
                    fullWidth
                    disabled={isAdminSuperUser}
                    margin={"normal"}
                    renderIcon={false}
                />

                {/* Store(s) Field */}
                <StoreAutoComplete />

                {/* Driver Insurance Coverage Expiration */}
                <DriverCoverageDateSelector />

                {/* Admin CheckBox */}
                <Typography fontWeight="900" variant={"body2"}>
                    User Type
                </Typography>
                <FormCheckboxElement name={"admin"} label={"Admin"} />
                <FormCheckboxElement name={"driver"} label={"Driver"} />
                <FormCheckboxElement name={"sales"} label={"Sales"} />

                {/* Submit Button */}
                <Button
                    variant="contained"
                    sx={{
                        mt: 2,
                        width: "30%",
                        backgroundColor: "#40ABBA",
                        ":hover": { backgroundColor: "#2a6574" },
                    }}
                    loading={
                        updateUserState.status === "loading" ||
                        createUserState.status === "loading" ||
                        removeStoreAccountState.status === "loading" ||
                        addStoreAccountState.status === "loading"
                    }
                    loadingPosition={"end"}
                >
                    {user ? "Edit User" : "Create User"}
                </Button>
            </Box>
        </FormContainer>
    );
};
