import { buildQuery, IFilter, IPage } from "@/api/api.ts";
import { API_URL } from "@/config";
import axios from "axios";
import { IDriverOrder, UserType } from "./profile";
import { IDiscountCode } from "./settings";
import {
    DBStoreOrder,
    DeliveryWindow,
    IAddress,
    IOrderImage,
    IPaymentIntent,
    IStoreOrder,
    ItemSize,
} from "./store";

const API = `${API_URL}/orders`;

export interface IOrderStatus {
    id: number;
    accepted: boolean;
    picked_up: boolean;
    picked_up_time: string;
    delivered: boolean;
    delivered_time: string;
    paid_driver: boolean;
}

export interface IUpdateOrderStatus {
    accepted?: boolean;
    picked_up?: boolean;
    delivered?: boolean;
    paid_driver?: boolean;
}

interface IOrderPaymentMetaData {
    assembly_cost: string;
    base_price: string;
    delivery_window_cost: string;
    distance_cost: string;
    floor_level_cost: string;
    max_weight_cost: string;
    max_weight: boolean;
    needs_wrapped: boolean;
    distance: string;
    distance_multiplier: string;
    delivery_window: DeliveryWindow;
    item_size: ItemSize;
    item_quantity: number;
    floor_level: number;
    elevator_available: boolean;
    assembly_required: boolean;
    discount?: string;
    high_value_cost?: string;
}

export interface IOrderPaymentInfo {
    amount: number;
    metadata: IOrderPaymentMetaData;
}

export interface ICustomMetaDataResponse {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    pickup_address_address1: string;
    pickup_address_address2: string;
    pickup_address_city: string;
    pickup_address_state: string;
    pickup_address_zip_code: string;
    delivery_address_address1: string;
    delivery_address_address2: string;
    delivery_address_city: string;
    delivery_address_state: string;
    delivery_address_zip_code: string;
    item_description: string;
    estimated_value: string;
    item_quantity: number;
    assembly_cost: string;
    base_price: string;
    delivery_window_cost: string;
    distance_cost: string;
    floor_level_cost: string;
    max_weight_cost: string;
    max_weight: boolean;
    needs_wrapped: boolean;
    distance: string;
    distance_multiplier: string;
    delivery_window: DeliveryWindow;
    item_size: ItemSize;
    floor_level: number;
    elevator_available: boolean;
    assembly_required: boolean;
    referral?: string;
    dropoff_instructions: string;
    discount?: string;
}

export interface ICustomOrder {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    pickup_address: IAddress;
    delivery_address: IAddress;
    item_description: string;
    estimated_value: string;
    item_quantity: number;
    item_size: string;
    delivery_window: string;
    floor_level: number;
    elevator_available: boolean;
    assembly_required: boolean;
    max_weight: boolean;
    needs_wrapped: boolean;
    payment_intent_id?: string;
    payment_intent_paid?: boolean;
    store_id?: number;
    driver?: UserType;
    referral?: string;
    dropoff_instructions: string;
    driver_bonus?: string;
    images?: IOrderImage[];
}

export interface IUpdateOrder {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number?: string;
    delivery_address?: IAddress;
    item_description?: string;
    estimated_value?: string;
    item_quantity?: number;
    item_size?: string;
    delivery_window?: string;
    floor_level?: number;
    elevator_available?: boolean;
    assembly_required?: boolean;
    max_weight?: boolean;
    needs_wrapped?: boolean;
    dropoff_instructions?: string;
    driver_bonus?: string;
}

export interface IDBCustomOrder extends ICustomOrder {
    referral?: string;
    id: number;
    created_at: string;
    accepted: boolean;
    accepted_time: string;
    picked_up: boolean;
    picked_up_time: string;
    expected_pickup_time: string;
    delivered: boolean;
    delivered_time: string;
    paid_driver: boolean;
    invoice_file?: string;
    invoice_uploaded_at?: string;
}

export interface IMetaDataResponse {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    pickup_address_address1: string;
    pickup_address_address2: string;
    pickup_address_city: string;
    pickup_address_state: string;
    pickup_address_zip_code: string;
    delivery_address_address1: string;
    delivery_address_address2: string;
    delivery_address_city: string;
    delivery_address_state: string;
    delivery_address_zip_code: string;
    item_description: string;
    item_quantity: number;
    assembly_cost: string;
    base_price: string;
    delivery_window_cost: string;
    distance_cost: string;
    floor_level_cost: string;
    max_weight_cost: string;
    max_weight: boolean;
    needs_wrapped: boolean;
    estimated_value: string;
    distance: string;
    distance_multiplier: string;
    delivery_window: DeliveryWindow;
    item_size: ItemSize;
    floor_level: number;
    elevator_available: boolean;
    assembly_required: boolean;
}

export interface ICustomOrderPaymentIntent {
    id: string;
    amount: number;
    fee: number;
    net_amount: number;
    status: string;
    client_secret: string;
    metadata: ICustomMetaDataResponse;
}

export const createCustomOrder = async (data: ICustomOrder) => {
    const result: { data: IDBCustomOrder } = await axios.post(
        `${API}/custom`,
        data
    );
    return result.data;
};

export const updateCustomOrder = async (
    order_id: number,
    data: IUpdateOrder
) => {
    const result: { data: IDBCustomOrder } = await axios.put(
        `${API}/custom/${order_id}`,
        data
    );
    return result.data;
};

export const getCustomOrder = async (order_id: number) => {
    const result: { data: IDBCustomOrder } = await axios.get(
        `${API}/custom/${order_id}`
    );
    return result.data;
};

export const getCustomOrders = async (
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<IPage<IDBCustomOrder>>(
        `${API}/custom?page=${page}&size=${size}&${buildQuery(filter)}`
    );
    return result.data;
};

export const softDeleteCustomOrder = async (order_id: number) => {
    const result = await axios.delete(`${API}/custom/${order_id}`);
    return result.data;
};

export const getStoreOrderByPaymentIntent = async (paymentIntentId: string) => {
    const result = await axios.get<IStoreOrder>(
        `${API}/stores/${paymentIntentId}`
    );
    return result.data;
};

export const createCustomOrderPaymentIntent = async (order_id: number) => {
    const result = await axios.post<IPaymentIntent>(
        `${API}/custom/${order_id}/payment`
    );
    return result.data;
};

export const updateCustomOrderPaymentIntent = async (order_id: number) => {
    const result = await axios.put(`${API}/custom/${order_id}/payment`);
    return result.data;
};

export const createPaymentIntent = async (order_id: number) => {
    const result = await axios.post<IPaymentIntent>(
        `${API}/${order_id}/payment`
    );
    return result.data;
};

export const getPaymentIntent = async (paymentIntentId: string) => {
    const result = await axios.get<ICustomOrderPaymentIntent>(
        `${API}/payment/${paymentIntentId}`
    );
    return result.data;
};

export const updatePaymentIntent = async (orderId: number) => {
    const result = await axios.put(`${API}/${orderId}/payment`);
    return result.data;
};

export const getOrder = async (order_id: number | string) => {
    const result: { data: DBStoreOrder } = await axios.get(
        `${API}/${order_id}`,
        {
            headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0",
            },
        }
    );
    return result.data;
};

export const updateOrderStatus = async (
    order_id: number,
    data: IUpdateOrderStatus
) => {
    const result: { data: IOrderStatus } = await axios.put(
        `${API}/${order_id}/status`,
        data
    );
    return result.data;
};

export const getOrderPaymentInfo = async (order_id: number | string) => {
    const result: { data: IOrderPaymentInfo } = await axios.get(
        `${API}/${order_id}/payment`
    );

    return result.data;
};

export const getUnacceptedOrders = async (
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<IPage<IDriverOrder>>(
        `${API}/driver/available?page=${page}&size=${size}&${buildQuery(
            filter
        )}`
    );
    return result.data;
};

export const uploadInvoice = async (order_id: number | string, file: File) => {
    const formData = new FormData();
    formData.append("invoice", file);
    const result: { data: { url: string } } = await axios.put(
        `${API}/${order_id}/invoice`,
        formData
    );
    return result.data;
};

export const removeInvoice = async (order_id: number | string) => {
    const result = await axios.delete(`${API}/${order_id}/invoice`);
    return result.data;
};

export const getStoresOrders = async (
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<IPage<DBStoreOrder>>(
        `${API}/stores?page=${page}&size=${size}&${buildQuery(filter)}`
    );
    return result.data;
};

export const checkDiscountCode = async (
    payment_intent: string,
    discount_code: string
) => {
    const result = await axios.post<IDiscountCode>(
        `${API}/discount/${payment_intent}/${discount_code}`
    );
    return result.data;
};

export const addOrderImage = async (order_id: string | number, image: File) => {
    const formData = new FormData();
    formData.append("image", image);

    const result = await axios.put(`${API}/${order_id}/image`, formData);

    return result.data;
};

export const deleteOrderImage = async (
    order_id: string | number,
    image_id: string | number
) => {
    const result = await axios.delete(`${API}/${order_id}/image/${image_id}`);
    return result.data;
};

export const getDraftOrders = async (
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<IPage<DBStoreOrder | IDBCustomOrder>>(
        `${API}/drafts/?page=${page}&size=${size}&${buildQuery(filter)}`
    );
    return result.data;
};
