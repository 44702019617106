import { IDBCustomOrder, IOrderPaymentInfo } from "../api/orders";
import { IPaymentIntent, IStoreOrder } from "../api/store";

export const calculateTotalExcludingDiscount = (
    paymentInfo: IPaymentIntent | IOrderPaymentInfo
) => {
    const meta = paymentInfo.metadata;
    const base = Number(meta.base_price);
    const assembly = Number(meta.assembly_cost);
    const delivery = Number(meta.delivery_window_cost);
    const distance = Number(meta.distance_cost);
    const floor = Number(meta.floor_level_cost);
    const weight = Number(meta.max_weight_cost);
    const high_value = Number(meta.high_value_cost);

    return base + assembly + delivery + distance + floor + weight + high_value;
};

export const getPaymentBreakdown = (
    paymentInfo: IPaymentIntent | IOrderPaymentInfo,
    order: IStoreOrder | IDBCustomOrder
) => {
    const breakdown = [
        {
            label: "Local Delivery Fee (Item Size)",
            desc: `${order.item_size} - ${
                order.item_quantity > 2
                    ? "3+ items"
                    : `${order.item_quantity} item`
            } `,
            value: paymentInfo.metadata.base_price,
        },
        {
            label: "Assembly Fee",
            desc: order.assembly_required ? "Required" : "Not Required",
            value: paymentInfo.metadata.assembly_cost,
        },
        {
            label: "Delivery Window Fee",
            desc: order.delivery_window,
            value: paymentInfo.metadata.delivery_window_cost,
        },
        {
            label: "Distance Fee",
            desc:
                paymentInfo.metadata.distance &&
                paymentInfo.metadata.distance_multiplier
                    ? `${Number(paymentInfo.metadata.distance).toFixed(2)} miles 
                  ($${Number(paymentInfo.metadata.distance_multiplier).toFixed(
                      2
                  )} / 1 mile)`
                    : "-",
            value: paymentInfo.metadata.distance_cost,
        },
        {
            label: "Floor Level Fee",
            desc: `Floor ${
                order.floor_level === 4 ? "3+" : order.floor_level
            } (${
                order.elevator_available ? "Elevator Available" : "No Elevator"
            })`,
            value: paymentInfo.metadata.floor_level_cost,
        },
        {
            label: "Max Weight Fee",
            desc: order.max_weight ? "Exceeds 100 lbs" : "Within Limit",
            value: paymentInfo.metadata.max_weight_cost,
        },
    ];

    if (paymentInfo.metadata.discount) {
        const split = paymentInfo.metadata.discount.split("|");
        const code = split[0].toUpperCase();
        const amount = split[1];
        breakdown.push({
            label: "Discount",
            desc: code,
            value: `-${amount}`,
        });
    }

    if (paymentInfo.metadata.high_value_cost !== "0") {
        breakdown.push({
            label: "High Value Item Fee",
            desc: "",
            value: `${paymentInfo.metadata.high_value_cost}`,
        });
    }

    return breakdown;
};
