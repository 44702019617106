import { useAsync } from "@react-hookz/web";
import React from "react";
import { SalesStoreAutoCompleteOption } from "./SalesStoreAutoCompleteOption";
import { IStore } from "@/api/store";
import { getSalesStores } from "@/api/profile";
import { FormAutoCompleteElement } from "@rhf-kit/mui";

export const SalesStoreAutoComplete = () => {
    const [stores, setStores] = React.useState<IStore[]>([]);

    const [getSalesStoresState, getSalesStoresActions] =
        useAsync(getSalesStores);

    // retrieve stores for autocomplete field
    React.useEffect(() => {
        getSalesStoresActions.execute();
    }, [getSalesStoresActions]);

    // set stores
    React.useEffect(() => {
        if (
            getSalesStoresState.status === "success" &&
            getSalesStoresState.result
        ) {
            setStores(getSalesStoresState.result.items);
        }
    }, [getSalesStoresState.status, getSalesStoresState.result]);
    return (
        <FormAutoCompleteElement
            name={"stores"}
            label={"Store(s) (Optional)"}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => (option as IStore).name}
            noOptionsText={"No stores"}
            size="small"
            autoComplete
            autoHighlight
            multiple
            includeInputInList
            options={stores}
            textFieldProps={{ margin: "normal" }}
            loading={getSalesStoresState.status === "loading"}
            renderOption={(props, option) => (
                <SalesStoreAutoCompleteOption
                    key={option.id}
                    option={option}
                    props={props}
                />
            )}
        />
    );
};
