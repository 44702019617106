import { API_URL } from "@/config";
import axios from "axios";
import { IPage } from "./api";

const API = `${API_URL}/settings`;

interface ISettings {
    id: number;
}

export interface IItemSizePricing extends ISettings {
    size_name: string;
    base_price: number;
}

export interface IDeliveryWindowPricing extends ISettings {
    window_type: string;
    additional_cost: number;
}

export interface IFloorLevelPricing extends ISettings {
    level: string;
    cost: number;
}

export interface IMiscCostPricing extends ISettings {
    cost_name: string;
    cost_value: number;
}

export interface IPricing extends ISettings {
    item_sizes: IItemSizePricing[];
    delivery_windows: IDeliveryWindowPricing[];
    floor_levels: IFloorLevelPricing[];
    misc_costs: IMiscCostPricing[];
}

export interface IItemCategory extends ISettings {
    category_name: string;
    parent_id: number | null;
    children: IItemCategory[];
}

export interface IItemCategoryUpdate extends ISettings {
    category_name: string;
    parent_id?: number | null;
}

export interface IItemCategoryCreate {
    category_name: string;
    parent_id: number | null;
}

export interface IDiscountCode extends ISettings {
    code: string;
    discount: number;
}

export interface IDiscountCodeCreate {
    code: string;
    discount: number;
}

export interface IDiscountCodeUpdate extends ISettings {
    code?: string;
    discount?: number;
}
export interface IHighValueUpcharge extends ISettings {
    minimum_value: number;
    upcharge: number;
}

export interface IHighValueUpchargeCreate {
    minimum_value: number;
    upcharge: number;
}

export interface IHighValueUpchargeUpdate extends ISettings {
    minimum_value?: number;
    upcharge?: number;
}

export const getPricing = async () => {
    const result: { data: IPricing } = await axios.get(`${API}/pricing`);
    return result.data;
};

export const updateItemSize = async (itemSize: IItemSizePricing) => {
    const result: { data: IItemSizePricing } = await axios.put(
        `${API}/item-size/${itemSize.id}`,
        itemSize
    );
    return result.data;
};

export const updateDeliveryWindow = async (
    deliveryWindow: IDeliveryWindowPricing
) => {
    const result: { data: IDeliveryWindowPricing } = await axios.put(
        `${API}/delivery-window/${deliveryWindow.id}`,
        deliveryWindow
    );
    return result.data;
};

export const updateFloorLevel = async (floorLevel: IFloorLevelPricing) => {
    const result: { data: IFloorLevelPricing } = await axios.put(
        `${API}/floor-level/${floorLevel.id}`,
        floorLevel
    );
    return result.data;
};

export const updateMiscCost = async (miscCost: IMiscCostPricing) => {
    const result: { data: IMiscCostPricing } = await axios.put(
        `${API}/misc-cost/${miscCost.id}`,
        miscCost
    );
    return result.data;
};

export const getItemCategories = async () => {
    const result: { data: IPage<IItemCategory> } = await axios.get(
        `${API}/item-category`
    );
    return result.data;
};

export const createItemCategory = async (itemCategory: IItemCategoryCreate) => {
    const result: { data: IItemCategory } = await axios.post(
        `${API}/item-category`,
        itemCategory
    );
    return result.data;
};

export const updateItemCategory = async (itemCategory: IItemCategoryUpdate) => {
    const result: { data: IItemCategory } = await axios.put(
        `${API}/item-category/${itemCategory.id}`,
        itemCategory
    );
    return result.data;
};

export const deleteItemCategory = async (id: number) => {
    await axios.delete(`${API}/item-category/${id}`);
};

export const getDiscountCodes = async () => {
    const result = await axios.get<IPage<IDiscountCode>>(
        `${API}/discount-codes`
    );

    return result.data;
};

export const createDiscountCode = async (discount: IDiscountCodeCreate) => {
    const result = await axios.post<IDiscountCode>(
        `${API}/discount-codes`,
        discount
    );

    return result.data;
};

export const updateDiscountCode = async (discount: IDiscountCodeUpdate) => {
    const result = await axios.put<IDiscountCode>(
        `${API}/discount-codes/${discount.id}`,
        discount
    );

    return result.data;
};

export const deleteDiscountCode = async (id: number) => {
    const result = await axios.delete<IDiscountCode>(
        `${API}/discount-codes/${id}`
    );
    return result.data;
};

export const getHighValues = async () => {
    const result = await axios.get<IPage<IHighValueUpcharge>>(
        `${API}/high-value`
    );

    return result.data;
};

export const createHighValue = async (highValue: IHighValueUpchargeCreate) => {
    const result = await axios.post<IHighValueUpcharge>(
        `${API}/high-value`,
        highValue
    );

    return result.data;
};

export const updateHighValue = async (highValue: IHighValueUpchargeUpdate) => {
    const result = await axios.put<IDiscountCode>(
        `${API}/high-value/${highValue.id}`,
        highValue
    );

    return result.data;
};

export const deleteHighValue = async (id: number) => {
    const result = await axios.delete<IHighValueUpcharge>(
        `${API}/high-value/${id}`
    );
    return result.data;
};
