import { getHighValues, IHighValueUpcharge } from "@/api/settings";
import { CreateHighValueModal } from "@/components/dashboard/high-value/CreateHighValueModal";
import { HighValueDeleteIcon } from "@/components/dashboard/high-value/DeleteHighValue";
import { UpdateHighValueModal } from "@/components/dashboard/high-value/UpdateHighValueModal";
import { Edit } from "@mui/icons-material";
import {
    Box,
    Card,
    Divider,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { FormButton } from "@rhf-kit/mui";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const HighValueForm = () => {
    const { isPending, isError, data, refetch } = useQuery({
        queryKey: ["admin-high-value-upcharges"],
        queryFn: getHighValues,
    });

    const [create, setCreate] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [selected, setSelected] = useState<IHighValueUpcharge>();

    if (isPending || !data) {
        return <Skeleton height={200} />;
    }

    if (isError) {
        throw new Error("Could not get discount codes");
    }

    return (
        <Card sx={{ borderRadius: "10px", p: 3 }}>
            <Typography
                variant="h6"
                fontWeight={600}
                textAlign="left"
                gutterBottom
            >
                High Value Upcharges
            </Typography>
            <Stack spacing={2} my={2}>
                {data.items.length === 0 && (
                    <Typography>No High Value Upcharges</Typography>
                )}
                {data.items.map((upcharge, index) => (
                    <>
                        <Stack
                            direction={"row"}
                            key={`upcharge-${index}`}
                            sx={{ justifyContent: "space-between" }}
                            display="flex"
                            alignItems="center"
                        >
                            <Typography>
                                Above $
                                {(upcharge.minimum_value / 100).toFixed(2)} (+ $
                                {(upcharge.upcharge / 100).toFixed(2)})
                            </Typography>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        setSelected(upcharge);
                                        setEdit(true);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                                <HighValueDeleteIcon highValue={upcharge} />
                            </Box>
                        </Stack>
                        <Divider />
                    </>
                ))}
            </Stack>
            <FormButton
                onClick={() => setCreate(true)}
                variant="contained"
                loading={false}
                sx={{
                    backgroundColor: "#40ABBA",
                    ":hover": { backgroundColor: "#2a6574" },
                }}
            >
                New High Value Upcharge
            </FormButton>
            <CreateHighValueModal
                open={create}
                setOpen={setCreate}
                mutateHighValues={refetch}
            />
            <UpdateHighValueModal
                open={edit}
                highValue={selected}
                setSelected={setSelected}
                setOpen={setEdit}
                mutateHighValues={refetch}
            />
        </Card>
    );
};
