import PeopleIcon from "@mui/icons-material/People";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { IStoreUser } from "@/api/users.ts";

interface IUserAutoCompleteOptionProps {
    option: IStoreUser;
    props: React.HTMLAttributes<HTMLLIElement>;
}

export const UserAutoCompleteOption: React.FC<IUserAutoCompleteOptionProps> = ({
    option,
    props,
}) => {
    return (
        <li {...props}>
            <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                    <PeopleIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                    <Box
                        component="span"
                        sx={{
                            fontWeight: "bold",
                        }}
                        textTransform={"capitalize"}
                    >
                        {option.first_name + " " + option.last_name}
                    </Box>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        textTransform={"lowercase"}
                    >
                        {option.email}
                    </Typography>
                </Grid>
            </Grid>
        </li>
    );
};
